//React
import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';

//Styles
import * as styles from './Cube.module.scss';

//Components
// import CubeOverlay from '../CubeOverlay/CubeOverlay';

const PAGE_LIST = {
    left: 'about',
    right: 'about',
    top: 'contact',
    bottom: 'contact',
    front: 'my-work',
    back: 'surprise'
}

const Cube = ({ transitionPages, removeTransitionPhase }) => {
    const [dragging, setDragging] = useState(false);
    const [dragOrigin, setDragOrigin] = useState({ x: 0, y: 0 });
    const [rotateX, setRotateX] = useState({ prev: 15, current: 15 });
    const [rotateY, setRotateY] = useState({ prev: 0, current: 0 });
    const [clickedFace, setClickedFace] = useState('');
    const MAX_ROTATION = 90;
    const [cubeStyles, setCubeStyles] = useState([styles.cube, styles.smoothRotate]);

    const rotateToFace = (event)=>{
        const styleXIndex = cubeRef.current.style.transform.indexOf('rotateY');
        const styleX = Number(cubeRef.current.style.transform.slice(styleXIndex + 8, -4));
        const addedRotation = Math.round(styleX / 360) * 360;

        const face = event.target.id;
        setCubeStyles((prevStyles)=>{
            return [
                ...prevStyles,
                styles.smoothRotate
            ]
        });
        switch (face) {
            case 'left':
                setRotateY({ prev: 0, current: 0 });
                setRotateX({ prev: 90 + addedRotation, current: 90 + addedRotation });
                break;
            case 'right':
                setRotateY({ prev: 0, current: 0 });
                setRotateX({ prev: -90 + addedRotation, current: -90 + addedRotation });
                break;
            case 'front':
                setRotateY({ prev: 0, current: 0 });
                setRotateX({ prev: 0 + addedRotation, current: 0 + addedRotation });
                break;
            case 'back':
                setRotateY({ prev: 0, current: 0 });
                setRotateX({ prev: 180 + addedRotation, current: 180 + addedRotation });
                break;
            case 'top':
                setRotateY({ prev: 90, current: 90 });
                setRotateX({ prev: 0 + addedRotation, current: 0 + addedRotation });
                break;
            case 'bottom':
                setRotateY({ prev: -90, current: -90 });
                setRotateX({ prev: 0 + addedRotation, current: 0 + addedRotation });
                break;
            default:
                return;
        }
    }

    const clickFace = useCallback((event) => {
        event.preventDefault();
        
        if (event.target.className.includes('face') || event.target.className.includes('pageLink')) {
           
            setCubeStyles([styles.cube, styles.fullSizeCube]);
            setClickedFace(event.target.id);
            transitionPages(`/${PAGE_LIST[event.target.id]}`);
            rotateToFace(event)
        }

    }, [transitionPages])

    const calculateRotate = useCallback((event) => {
      
        let originX = dragOrigin.x;
        let originY = dragOrigin.y;
        let clientX = 0;
        let clientY = 0;


        if (event.type === 'mousemove') {
            clientX = event.clientX;
            clientY = event.clientY;
        } else if (event.type === 'touchmove') {
            clientX = event.touches[0].pageX;
            clientY = event.touches[0].pageY;
        }

        let deltaX = (clientX - originX) / 2;
        let deltaY = (clientY - originY) / 2;

        setRotateX((prevState) => {
            return {
                ...prevState,
                current: prevState.prev + deltaX
            }
        });
        setRotateY((prevState) => {
            if (deltaY >= 0) {
                return {
                    ...prevState,
                    current: (Math.abs(prevState.prev + deltaY)) > MAX_ROTATION ? MAX_ROTATION : prevState.prev + deltaY
                }
            } else {
                return {
                    ...prevState,
                    current: (Math.abs(prevState.prev + deltaY)) > MAX_ROTATION ? -MAX_ROTATION : prevState.prev + deltaY
                }
            }
        });
    }, [dragOrigin.x, dragOrigin.y])

    const startDragging = useCallback((event) => {
        event.preventDefault();
        setCubeStyles([styles.cube])
        removeTransitionPhase();
        document.addEventListener('mouseup', clickFace, { passive: false })
        document.addEventListener('touchend', clickFace, { passive: false })
        if (event.type === 'mousedown') {
            setDragOrigin({ x: event.clientX, y: event.clientY });
        } else if (event.type === 'touchstart') {
            setDragOrigin({ x: event.touches[0].pageX, y: event.touches[0].pageY })
        }

        setTimeout(() => {
            document.removeEventListener('mouseup', clickFace, { passive: false })
            document.removeEventListener('touchend', clickFace, { passive: false })
        }, 100)

        setDragging(true);
    }, [clickFace, removeTransitionPhase])

    const stopDragging = () => {
        setDragging(false);
        setRotateX((prevState) => {
            return {
                prev: prevState.current,
                current: prevState.current
            }
        });
        setRotateY((prevState) => {
            return {
                prev: prevState.current,
                current: prevState.current
            }
        });
    }
    const keyRotate = useCallback((event) => {
        setCubeStyles(([styles.cube, styles.smoothRotate]));
        if(event.type === 'keydown'){
        if (event.keyCode === 37) {
            setRotateX((prevState) => {
                return {
                    prev: prevState.current - 90,
                    current: prevState.current - 90
                }
            });
        }
        if (event.keyCode === 39) {
            setRotateX((prevState) => {
                return {
                    prev: prevState.current + 90,
                    current: prevState.current + 90
                }
            });
        }
        if (event.keyCode === 38) {
            setRotateY((prevState) => {
                const movement = prevState.current - 90 >= -MAX_ROTATION ? prevState.current - 90 : -MAX_ROTATION;
                return {
                    prev: movement,
                    current: movement
                }
            });
        }
        if (event.keyCode === 40) {
            setRotateY((prevState) => {
                const movement = prevState.current + 90 <= MAX_ROTATION ? prevState.current + 90 : MAX_ROTATION;
                return {
                    prev: movement,
                    current: movement
                }
            });
        }
        else{
            return;
        }
    }
        else {
            return;
        }
    }, [])

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', calculateRotate, { passive: false });
            document.addEventListener('touchmove', calculateRotate, { passive: false });
            document.addEventListener('mouseup', stopDragging);
            document.addEventListener('touchend', stopDragging);
            return () => {
                document.removeEventListener('mousemove', calculateRotate, { passive: false })
                document.removeEventListener('touchmove', calculateRotate, { passive: false })
                document.removeEventListener('mouseup', stopDragging)
                document.removeEventListener('touchend', stopDragging)
            }
        }
    }, [dragging, calculateRotate])

    useEffect(() => {
        document.addEventListener('keydown', keyRotate);
        document.addEventListener('keyup', keyRotate);
        frontRef.current.addEventListener('touchstart', startDragging, { passive: false })
        backRef.current.addEventListener('touchstart', startDragging, { passive: false })
        leftRef.current.addEventListener('touchstart', startDragging, { passive: false })
        rightRef.current.addEventListener('touchstart', startDragging, { passive: false })
        topRef.current.addEventListener('touchstart', startDragging, { passive: false })
        bottomRef.current.addEventListener('touchstart', startDragging, { passive: false })
    }, [keyRotate, startDragging])

    const cubeRef = useRef();
    const frontRef = useRef();
    const backRef = useRef();
    const leftRef = useRef();
    const rightRef = useRef();
    const topRef = useRef();
    const bottomRef = useRef();

    return (
        <React.Fragment>
            {/* <CubeOverlay visible={!dragging}/> */}
            <div className={cubeStyles.join(' ')}
                ref={cubeRef}
                style={{ transform: `rotateX(${-rotateY.current}deg) rotateY(${rotateX.current}deg)` }}>
                <figure
                    id='top'
                    ref={topRef}
                    className={
                        `${styles.face} 
                    ${styles.top} 
                    ${clickedFace === 'top' ? styles.active : ''}`}
                    onMouseDown={startDragging}
                >
                    <p>Contact</p>
                    <div className={styles.backFace}>
                       
                    </div>
                </figure>
                <figure
                    id='bottom'
                    ref={bottomRef}
                    className={
                        `${styles.face} 
                    ${styles.bottom} 
                    ${clickedFace === 'bottom' ? styles.active : ''}`}
                    onMouseDown={startDragging}
                >
                    <p>Contact</p>
                    <div className={styles.backFace}>
                        
                    </div>
                </figure>
                <figure
                    id='left'
                    ref={leftRef}
                    className={
                        `${styles.face} 
                    ${styles.left} 
                    ${clickedFace === 'left' ? styles.active : ''}`}
                    onMouseDown={startDragging}
                >
                    <p>About Me</p>
                    <div className={styles.backFace}></div>

                </figure>
                <figure
                    id='right'
                    ref={rightRef}
                    className={
                        `${styles.face} 
                    ${styles.right} 
                    ${clickedFace === 'right' ? styles.active : ''}`}
                    onMouseDown={startDragging}
                >
                    <p>About Me</p>
                    <div className={styles.backFace}>
                       
                    </div>
                </figure>
                <figure
                    id='front'
                    ref={frontRef}
                    className={
                        `${styles.face} 
                    ${styles.front} 
                    ${clickedFace === 'front' ? styles.active : ''}`}
                    onMouseDown={startDragging}
                >
                    <p>My Work</p>
                    <div className={styles.backFace}>
                        
                    </div>
                </figure>
                <figure
                    id='back'
                    ref={backRef}
                    className={
                        `${styles.face} 
                    ${styles.back} 
                    ${clickedFace === 'back' ? styles.active : ''}`}
                    onMouseDown={startDragging}
                >
                    <p>???</p>
                    <div className={styles.backFace}>
                        
                    </div>
                </figure>

            </div> 
            <div className={styles.pageLinksRow}>
                <div className={styles.buttonWrapper}>
                    <button id="front" className={`${styles.pageLink} ${styles.myWorkButton}`} onClick={clickFace} onMouseEnter={dragging ? null : rotateToFace}>Work</button>
                </div>
                <div className={styles.buttonWrapper}>
                    <button id="left" className={`${styles.pageLink} ${styles.aboutButton}`} onClick={clickFace} onMouseEnter={dragging ? null : rotateToFace}>About</button>
                </div>
                <div className={styles.buttonWrapper}>
                    <button id="top" className={`${styles.pageLink} ${styles.contactButton}`} onClick={clickFace} onMouseEnter={dragging ? null : rotateToFace}>Contact</button>
                </div>
                {/* <button id="back" className={`${styles.pageLink} ${styles.surpriseButton}`} onClick={clickFace} onMouseEnter={rotateToFace}>???</button> */}
            </div>
        </React.Fragment>
    )
}

export default Cube;