//React
import * as React from 'react';

//Styles
import * as styles from './Stage.module.scss';

//Components
import Cube from '../Cube/Cube';

const Stage = ({screenTransitioning, transitionPages, removeTransitionPhase})=>{
    return(
        <section className={[styles.Stage, screenTransitioning ? styles.transitioning : ''].join(' ')}>
            <Cube transitionPages={transitionPages} removeTransitionPhase={removeTransitionPhase}/>
        </section>
    )
}

export default Stage;