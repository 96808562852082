//React
import * as React from "react"
import {useState, useCallback} from 'react';

//Gatsby
import { navigate} from "gatsby";

//Styles
import * as styles from './index.module.scss';

//Components
import Layout from "../components/Layout/Layout";
import Stage from "../components/Stage/Stage";
import Seo from "../components/seo";

const IndexPage = ({location}) => {
  const [screenTransitioning, setScreenTransitioning] = useState(false);

  const transitionPages = useCallback((page)=>{
      setScreenTransitioning(true);
      setTimeout(()=>{
        navigate(
          page,
          {state: {
              cubeTransition: true
            }}
        )
      }, 1500)
  }, [])
  const removeTransitionPhase = useCallback(()=>{
    setScreenTransitioning(false);
  }, [])
  return (
    <Layout location={location}>
      <Seo/>
      <main className={styles.homePage}>
        <div className={styles.titleWrapper}>
        <h1 className={styles.title}>Dan Kolonay</h1>
        <div className={styles.titleFlex}>
        <p className={[styles.subtitle, styles.webDev].join(' ')}>Web Developer</p>
        <div></div>
        <p className={[styles.subtitle, styles.uxDesigner].join(' ')}>UX Designer</p>
        </div>
        </div>
    
        <Stage screenTransitioning={screenTransitioning} transitionPages = {transitionPages} removeTransitionPhase={removeTransitionPhase}/>
       
      </main>
    </Layout> 

  )
}

export default IndexPage
