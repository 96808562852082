// extracted by mini-css-extract-plugin
export var aboutButton = "Cube-module--aboutButton--9e222";
export var active = "Cube-module--active--00590";
export var back = "Cube-module--back--9080a";
export var backEnter = "Cube-module--backEnter--20820";
export var backExit = "Cube-module--backExit--5310b";
export var backFace = "Cube-module--backFace--72f68";
export var bottom = "Cube-module--bottom--3229a";
export var bottomEnter = "Cube-module--bottomEnter--a9c9d";
export var bottomExit = "Cube-module--bottomExit--2eb52";
export var buttonWrapper = "Cube-module--buttonWrapper--f2cd5";
export var contactButton = "Cube-module--contactButton--cf77f";
export var cube = "Cube-module--cube--4cf0e";
export var face = "Cube-module--face--f4203";
export var fadeText = "Cube-module--fadeText--a18bd";
export var flipBack = "Cube-module--flipBack--72f20";
export var flipBottom = "Cube-module--flipBottom--c1a0d";
export var flipFront = "Cube-module--flipFront--fa1a7";
export var flipLeft = "Cube-module--flipLeft--20d13";
export var flipRight = "Cube-module--flipRight--e6609";
export var flipTop = "Cube-module--flipTop--3ad48";
export var focusFace = "Cube-module--focusFace--36881";
export var front = "Cube-module--front--0fdd5";
export var frontEnter = "Cube-module--frontEnter--7356c";
export var frontExit = "Cube-module--frontExit--01e18";
export var fullSizeCube = "Cube-module--fullSizeCube--9566c";
export var growCube = "Cube-module--growCube--b7468";
export var growFace = "Cube-module--growFace--4d55e";
export var left = "Cube-module--left--420c5";
export var leftEnter = "Cube-module--leftEnter--ca39d";
export var leftExit = "Cube-module--leftExit--dcc48";
export var myWorkButton = "Cube-module--myWorkButton--09caf";
export var pageLink = "Cube-module--pageLink--dd261";
export var pageLinksRow = "Cube-module--pageLinksRow--03144";
export var right = "Cube-module--right--76b2f";
export var rightEnter = "Cube-module--rightEnter--66df9";
export var rightExit = "Cube-module--rightExit--e7e03";
export var smoothRotate = "Cube-module--smoothRotate--83d9b";
export var surpriseButton = "Cube-module--surpriseButton--0dab6";
export var top = "Cube-module--top--2aa5f";
export var topEnter = "Cube-module--topEnter--b25e3";
export var topExit = "Cube-module--topExit--68907";